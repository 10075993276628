.reservation {
  display: flex;
  gap: 3%;
  justify-content: center;
  @media screen and (min-width: 65rem) {
    max-width: 64rem;
  }
}

.reservation__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap--small);
  @media screen and (min-width: 48rem) {
    width: 50%;
    gap: 10%;
  }
}
