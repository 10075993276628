.mobile-menu__button {
  background-color: transparent;
  border: none;
  position: fixed;
  z-index: 5;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
}

.mobile-menu__button__icon {
  fill: var(--website-secondary-color);
  width: 100%;
  height: 100%;
}
