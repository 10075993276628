.mobile-menu {
  display: flex;
  position: fixed;
  width: 0;
  flex-direction: column;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 100%;
  transition: opacity 0.6s linear, visibility 0.6s linear, height 0.6s linear;
  overflow: hidden;
  background-color: var(--website-main-color);
  z-index: 4;
  align-items: center;
  justify-content: space-between;
  padding: 6rem;
  font-size: 1.4rem;
}

.mobile-menu--open {
  opacity: 1;
  visibility: visible;
  height: 100vh;
}
