.link {
  position: relative;
  color: var(--website-secondary-color);
  transition: color var(--transition);
  font-size: 1.5rem;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    padding-bottom: 0.006rem;
    transform: scaleX(0);
    height: 0.125rem;
    bottom: -0.25rem;
    left: 0;
    background-color: var(--website-secondary-color-hover);
    transform-origin: bottom right;
    transition: transform var(--transition);
  }
  &:hover {
    color: var(--website-secondary-color-hover);
    & > .header__button__icon {
      fill: var(--website-secondary-color-hover);
    }
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.link--active {
  position: relative;
  color: var(--website-secondary-color-hover);
  transition: color var(--transition);
  font-size: 1.5rem;
  & > .header__button__icon {
    fill: var(--website-secondary-color-hover);
    transition: fill var(--transition);
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.125rem;
    bottom: -0.25rem;
    left: 0;
    padding-bottom: 0.006rem;
    background-color: var(--website-secondary-color-hover);
  }
  & > svg {
    fill: var(--website-secondary-color-hover);
  }
}

.header__button__icon {
  fill: var(--website-secondary-color);
  margin-left: 0.5rem;
  max-height: 1.5rem;
  max-width: 1.5rem;
}
