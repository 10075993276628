* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #836131;
  background-color: var(--website-main-color);
  overflow-x: hidden;
}

code {
  font-family: 'Nunito Sans', sans-serif;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #836131;
}

img {
  display: block;
}

button {
  cursor: pointer;
}
