.footer__icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
  height: fit-content;
  @media screen and (min-width: 64rem) {
    gap: 0.6rem;
  }
}

.footer__icons__icon {
  width: 1.875rem;
  height: 1.875rem;
  fill: var(--text-color);
  transition: fill var(--transition);
  &:hover {
    fill: var(--website-secondary-color-hover);
  }
  @media screen and (min-width: 23rem) {
    width: 2.8rem;
    height: 2.8rem;
  }
  @media screen and (min-width: 48rem) {
    width: 3.75rem;
    height: 3.75rem;
  }
  @media screen and (min-width: 64rem) {
    width: 4.5rem;
    height: 4.5rem;
  }
}
