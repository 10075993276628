.menu-wine__download-button {
  background-color: transparent;
  border: 2px solid var(--website-secondary-color);
  padding: 0.5rem;
  color: var(--website-secondary-color);
  transition: border-color var(--transition), color var(--transition);
  &:hover {
    border-color: var(--website-secondary-color-hover);
    color: var(--website-secondary-color-hover);
  }
  @media screen and (min-width: 32rem) {
    font-size: var(--font-size-medium);
  }
}
