.menu {
  display: flex;
  flex-direction: column;
  gap: var(--gap--small);
  margin: 0 auto var(--gap--large) auto;
  color: var(--text-color);
  text-align: center;
  align-items: center;
  margin-top: var(--gap--large);
  @media screen and (min-width: 64rem) {
    width: 100%;
    max-width: 50rem;
    align-items: normal;
  }
}

.menu__alcohols__content--page {
  padding: 1.87rem;
  display: flex;
  border: 1px solid var(--website-secondary-color);
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  @media screen and (min-width: 48rem) {
    flex-direction: row;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.menu__button__arrow--left,
.menu__button__arrow--right {
  background-color: transparent;
  border: none;
  & > svg {
    width: 3.75rem;
    height: 3.75rem;
    fill: var(--website-secondary-color);
    transition: fill var(--transition);
    &:hover {
      fill: var(--website-secondary-color-hover);
    }
  }
}

.menu__button__arrow--left {
  transform: scaleX(-1);
}
