.newYear__title {
  color: var(--text-color);
  font-size: var(--font-size-medium);
  @media screen and (min-width: 32rem) {
    font-size: var(--font-size-large);
  }
  @media screen and (min-width: 64rem) {
    font-size: var(--font-size-title-desktop);
  }
}
