.section {
  padding: 1rem 1rem var(--gap--small) 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--website-main-color);
  width: 100%;
  color: var(--text-color);
  @media screen and (min-width: 64rem) {
    padding: 1rem 1rem var(--gap--large) 1rem;
    height: 100%;
    font-size: var(--font-size-medium);
  }
}

.section__title {
  color: var(--website-secondary-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large);
  padding: var(--gap--small) 0;
  @media screen and (min-width: 64rem) {
    font-size: var(--font-size-title-desktop);
    padding: var(--gap--large) 0;
  }
}

.section::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 0.125rem;
  background-color: var(--website-secondary-color);
}

@media screen and (min-width: 64rem) {
  .section::after {
    width: 100vw;
  }
}
