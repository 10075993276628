.header__mobile-menu-button {
  background-color: transparent;
  border: none;
  height: 4.5rem;
  width: 4.75rem;
  & > svg {
    fill: var(--website-secondary-color);
    height: 4.5rem;
    width: 4.75rem;
    @media screen and (min-width: 48rem) {
      height: 4.375rem;
    }
  }

  @media screen and (min-width: 64rem) {
    display: none;
  }
}
