.hero {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -49.58vw;
  margin-right: -49.58vw;
  @media screen and (min-width: 64rem) {
    width: 99vw;
  }
}

.hero::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 0.125rem;
  background-color: var(--website-secondary-color);
}

@media screen and (min-width: 64rem) {
  .hero::after {
    width: 100vw;
  }
}
