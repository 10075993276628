.wine__page__categories-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 0.938rem;
  @media screen and (min-width: 64rem) {
    gap: 0;
  }
}

.wine__page__categories-buttons--button {
  background-color: transparent;
  border: 2px solid var(--website-secondary-color);
  padding: 0.5rem;
  color: var(--website-secondary-color);
  transition: border-color var(--transition), color var(--transition);
  &:hover {
    border-color: var(--website-secondary-color-hover);
    color: var(--website-secondary-color-hover);
  }
  @media screen and (min-width: 32rem) {
    font-size: var(--font-size-medium);
  }
}

.wine__page__categories-buttons--button--active {
  transition: border-color var(--transition), background-color var(--transition),
    color var(--transition);
  border-color: var(--website-secondary-color-hover);
  color: var(--website-secondary-color-hover);
}
