.button {
  font-family: var(--font-primary);
  background-color: transparent;
  color: var(--website-secondary-color);
  font-size: var(--font-size-medium);
  outline: none;
  border-radius: 50%;
  padding: 0.43rem;
  border: 0.125rem var(--website-secondary-color) solid;
  transition: color var(--transition), border var(--transition);
  width: 3.125rem;
  height: 3.125rem;
  &:hover {
    color: var(--website-secondary-color-hover);
    border: 0.125rem var(--website-secondary-color-hover) solid;
  }
}
