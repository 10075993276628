.events {
  margin: 9rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.125rem;
  text-align: center;
  width: 100%;
  color: var(--text-color);
  @media screen and (min-width: 64rem) {
    gap: var(--gap--large);
    font-size: var(--font-size-medium);
  }
}
