.container {
  max-width: 30rem;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;

  @media screen and (min-width: 48rem) {
    max-width: 48rem;
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 64rem) {
    max-width: 64rem;
  }
}
