.header__navigation {
  display: none;
  flex: 1;
  @media screen and (min-width: 64rem) {
    display: flex;
    justify-content: space-around;
    font-size: var(--font-size-drop-down-menu);
  }
}

.header__content {
  display: flex;
  align-items: center;
  background-color: var(--header-background-transparent);
  justify-content: space-between;
  padding: 1rem 1rem;
  @media screen and (min-width: 64rem) {
    justify-content: center;
  }
}

.header_icon {
  fill: var(--website-secondary-color);
  transition: fill var(--transition);
  width: 2rem;
  height: 1.1rem;
  /* display: none; */
  &:hover {
    fill: var(--website-secondary-color-hover);
  }
}
