.button {
  font-family: var(--font-primary);
  --offset: 10px;
  --border-size: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.938rem;
  display: flex;
  position: relative;
  gap: 0.5rem;
  appearance: none;
  border: 0;
  background-color: transparent;
  color: var(--website-secondary-color);
  letter-spacing: 0.25rem;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 0;
  box-shadow: inset 0 0 0 var(--border-size) currentcolor;
  transition: all var(--transition);
  font-size: var(--font-size-medium);
  @media screen and (min-width: 64rem) {
    padding: 1.5rem 3rem;
    font-size: var(--font-size-large);
  }
  &:hover {
    & > span > a {
      color: var(--website-secondary-color-hover);
    }
    color: var(--website-secondary-color-hover);
  }
  & > span {
    flex: 1;
  }

  .button__horizontal,
  .button__vertical {
    color: var(--website-secondary-color);
    position: absolute;
    top: var(--horizontal-offset, 0);
    right: var(--vertical-offset, 0);
    bottom: var(--horizontal-offset, 0);
    left: var(--vertical-offset, 0);
    transition: transform 0.6s linear;
    will-change: transform;

    &::before {
      content: '';
      position: absolute;
      border: inherit;
    }
  }

  .button__horizontal {
    --vertical-offset: calc(var(--offset) * -1);
    border-top: var(--border-size) solid currentcolor;
    border-bottom: var(--border-size) solid currentcolor;

    &::before {
      top: calc(var(--vertical-offset) - var(--border-size));
      bottom: calc(var(--vertical-offset) - var(--border-size));
      left: calc(var(--vertical-offset) * -1);
      right: calc(var(--vertical-offset) * -1);
    }
  }
  .button__vertical {
    --horizontal-offset: calc(var(--offset) * -1);
    border-left: var(--border-size) solid currentcolor;
    border-right: var(--border-size) solid currentcolor;

    &::before {
      top: calc(var(--horizontal-offset) * -1);
      bottom: calc(var(--horizontal-offset) * -1);
      left: calc(var(--horizontal-offset) - var(--border-size));
      right: calc(var(--horizontal-offset) - var(--border-size));
    }
  }
  &:hover .button__horizontal {
    transform: scaleX(0);
  }
  &:hover .button__vertical {
    transform: scaleY(0);
  }
}
