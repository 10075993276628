.menu {
  display: flex;
  flex-direction: column;
  gap: var(--gap--large);
  align-items: center;
  @media screen and (min-width: 64rem) {
    width: 100%;
    max-width: 64rem;
    align-items: normal;
  }
}

.menu__buttons {
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  margin-bottom: var(--gap--large);
  @media screen and (min-width: 64rem) {
    max-width: none;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
}

.menu__content {
  @media screen and (min-width: 48rem) {
    display: flex;
    justify-content: space-around;
    gap: var(--gap--small);
  }
}
