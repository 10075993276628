.reservation {
  padding: 1rem 1rem var(--gap--large) 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--website-main-color);
  width: 100%;
  color: var(--text-color);
  margin-bottom: var(--gap--large);
  @media screen and (min-width: 64rem) {
    padding: 1rem 1rem var(--gap--large) 1rem;
    height: 100%;
    font-size: var(--font-size-medium);
  }
}

.reservation__title {
  color: var(--website-secondary-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large);
  padding: var(--gap--small) 0;
  @media screen and (min-width: 64rem) {
    font-size: var(--font-size-title-desktop);
    padding: var(--gap--large) 0;
  }
}
