.team {
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.125rem;
  width: 100%;
  color: var(--text-color);
  @media screen and (min-width: 64rem) {
    font-size: var(--font-size-medium);
  }
}
