.newYear__list__icon,
.newYear__list__icon--last {
  fill: var(--website-secondary-color);
  width: 1rem;
  height: 1rem;
  margin-top: 0.5rem;
}

.newYear__list__details--starters,
.newYear__list__details--mains,
.newYear__list__details--desserts,
.newYear__list__details--onStart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}

.newYear__list__details--starters,
.newYear__list__details--starter1 {
  &:nth-last-child(-n + 2) > svg {
    display: none;
  }
  & > div > svg {
    display: none;
  }
  &:nth-last-child(-2n + 2) div > svg {
    display: inline-block;
  }
}

.newYear__list__details--mains {
  &:nth-last-child(-n + 1) > svg {
    display: none;
  }
}

.newYear__list__details--desserts,
.newYear__list__details--onStart {
  &:nth-child(2) > svg {
    display: none;
  }
}

.newYearContainer {
  padding: 1rem;
}
