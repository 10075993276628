.header__logo > svg,
.header__logo {
  fill: var(--website-secondary-color);
  height: 4.5rem;
  max-width: 4.75rem;
  @media screen and (min-width: 48rem) {
    height: 4.375rem;
    max-width: none;
  }
}
