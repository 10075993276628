.menu {
  display: flex;
  flex-direction: column;
  gap: var(--gap--large);
  align-items: center;
  margin: 0 auto var(--gap--large) auto;
  color: var(--text-color);
  margin-top: var(--gap--large);
  padding-bottom: var(--gap--large);
  @media screen and (min-width: 64rem) {
    width: 100%;
    max-width: 50rem;
    align-items: normal;
  }
}

.menu__content {
  padding: 1.87rem;
  border: 1px solid var(--website-secondary-color);
  @media screen and (min-width: 48rem) {
    display: flex;
    justify-content: space-around;
    gap: var(--gap--small);
  }
}
