.wine__list {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.938rem 0;
}

.wine__title {
  font-size: var(--font-size-medium);
  padding-top: 1rem;
}

.wine__volume {
  display: flex;
  justify-content: space-around;
  align-self: flex-end;
  width: 9.375rem;
  font-size: 0.875rem;
  @media screen and (min-width: 26.5rem) {
    width: 11.563rem;
    font-size: 1rem;
  }
}

.wine__list__item {
  display: flex;
  justify-content: space-between;
}

.wine__list__name--glass,
.wine__list__name--bottle > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  & > p:nth-child(1) {
    font-size: 1rem;
  }
  & > p:nth-child(2) {
    font-size: 0.875rem;
  }
  @media screen and (min-width: 32rem) {
    & > p:nth-child(1) {
      font-size: 1.125rem;
    }
    & > p:nth-child(2) {
      font-size: 0.875rem;
    }
  }
}

.wine__list__name--bottle {
  display: flex;
  max-width: 43rem;
  align-items: center;
  & > p {
    width: 3.125rem;
    margin-right: 0.625rem;
    @media screen and (min-width: 32rem) {
      margin-right: 0;
    }
  }
  & > div {
    width: fit-content;
    @media screen and (min-width: 64rem) {
      width: max-content;
    }
  }
}

.wine__list__price {
  display: flex;
  justify-content: space-around;
  width: 50%;
  @media screen and (min-width: 32rem) {
    width: 11.563rem;
  }
}

.wine__list__price--bottle {
  width: fit-content;
  margin-left: 0.625rem;
  text-align: end;
  max-width: 4rem;
}

.wine__category-name {
  font-size: var(--font-size-large);
  @media screen and (min-width: 48rem) {
    text-align: center;
  }
}

.wine__category-grape {
  text-decoration: underline;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
}

.wine__category-country {
  font-size: var(--font-size-drop-down-menu);
}
