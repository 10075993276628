.icon__arrow--up {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  fill: var(--website-secondary-color);
  transition: fill var(--transition);
  &:hover {
    fill: var(--website-secondary-color-hover);
  }
}
